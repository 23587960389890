.fieldset {
  border: None;
  padding: 0;
}

legend {
  font-size: 2rem;
}

.widget--textinput,
.widget--textarea,
.widget--emailinput,
.widget--urlinput,
.widget--select,
.widget--checkboxselectmultiple,
.widget--typedvaluelistwidget,
.widget--multiplechoiceextrawidget,
.widget--hourswidget {
  margin-bottom: 2rem;

  > .columns > label:first-child {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  ul {
    margin: 0;
  }
}

.widget--checkboxselectmultiple {
  ul {
    @media #{$medium-up} {
      columns: 2;
    }

    label {
      padding: 0 0 0 1.5rem;
      margin-bottom: 0.5rem;
      line-height: 1.2rem;

      input {
        margin: 0 0.45rem 0 -1.5rem;
      }
    }

    &#id_service_languages {
      columns: 2;
      @media #{$medium-up} {
        columns: 3;
      }
      @media #{$large-up} {
        columns: 4;
      }
    }
  }
}

.widget--typedvaluelistwidget {
  button {
    border: 1px solid silver;
    background: none;

    &:hover,
    &:active,
    &:focus {
      background: none;
      border-color: $primary-color;
    }
  }
}

.errorlist {
  border: 1px solid $alert-color;
  background: lighten($alert-color, 20%);
  padding: 0.5rem 1rem;
  margin: 0;

  li {
    list-style: none;
  }
}

.form-summary {
  font-size: 1rem;
}

.hint {
  color: $primary-color;
}
